import logo from './logo.svg';
import './App.css';
// import instance from "../src/views/api/api_instance";
import { useState, useEffect } from "react";
import axios from "axios";


function App() {





  const tokens = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2Mzc0ZTgxYmZkOWFhYzIyNjcyMmE2NjMiLCJpYXQiOjE2Njg3MDQ0OTksImV4cCI6MTY2ODc5MDg5OX0.dVX69SR1glf_oxKPJSxGmiml4Yh1zZ35vJjU7z8SI7o'
  localStorage.setItem("accessToken", JSON.stringify(tokens));
  axios.defaults.headers.common["Authorization"] = tokens;

  const optionss = {
    headers: {
      "content-type": "application/json",
      'Access-Control-Allow-Origin': '*',
    }
  }
  axios.get("http://api.cup2022.ir/api/v1/team", optionss);

  // const data = JSON.stringify({ Authorization: tokens });
  // const options = {
  //   headers: { "content-type": "application/json" }
  // }
  // axios.post("/api/v1/team", data, options);

  // axios.defaults.headers.post['Content-Type'] = 'application/json';
  // axios.defaults.headers.common['Authorization'] = tokens;

  // var url = "http://api.cup2022.ir/api/v1/team";

  // var xhr = new XMLHttpRequest();
  // xhr.open("GET", url);

  // xhr.setRequestHeader("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2Mzc0ZTgxYmZkOWFhYzIyNjcyMmE2NjMiLCJpYXQiOjE2Njg3MDQ0OTksImV4cCI6MTY2ODc5MDg5OX0.dVX69SR1glf_oxKPJSxGmiml4Yh1zZ35vJjU7z8SI7o");

  // xhr.onreadystatechange = function () {
  //   if (xhr.readyState === 4) {
  //     console.log(xhr.status);
  //     console.log(xhr.responseText);
  //   }
  // };

  // xhr.send();


  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   axios.post('http://api.cup2022.ir/api/v1/team', {
  //     data: {
  //       'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2Mzc0ZTgxYmZkOWFhYzIyNjcyMmE2NjMiLCJpYXQiOjE2Njg3MDQ0OTksImV4cCI6MTY2ODc5MDg5OX0.dVX69SR1glf_oxKPJSxGmiml4Yh1zZ35vJjU7z8SI7o', // This is the body part
  //       'Content-Type': 'application/json',
  //       'accept': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       "Authorization": tokens,

  //     },
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'accept': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //       timeout: 1000,
  //     },
  //   }

  //   ).then(res => {

  //     console.log(res);
  //   })


  // }, [])




  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
